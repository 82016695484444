import React, { useState, useEffect, useRef, ReactNode, forwardRef } from "react";
import styled, { keyframes, css } from "styled-components";
import { theme } from "@upsolve/ui";
import ChatIcon from "./ChatIcon";
import { IChatMessage, IUserContext, useChat } from "./useChat";
import CloseButton from "../../../static/images/close-button.svg";
import SendIcon from "./images/send-icon.svg";
import Sparkles from "./images/sparkles-icon-dark.svg";
import PageIcon from "./images/page-icon.svg";
import MessageIcon from "./images/chat-icon.svg";
import { QuestionAnswerFormat } from "./QuestionAnswerFormat";
import { MessageText } from "./MessageText";
import { FeedbackButtons } from "./FeedbackButtons";
import { EVENTS } from "@upsolve/shared";
import { track } from "../analytics/track";

const slideUp = keyframes`
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

const slideDown = keyframes`
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(100%);
    opacity: 0;
  }
`;

const pulse = keyframes`
  0%, 100% { opacity: 0.5; }
  50% { opacity: 1; }
`;

const ChatWindow = styled.div<{ isOpen: boolean; isMidClosingAnimation: boolean }>`
  position: fixed;
  z-index: 5000;
  top: 0;
  right: 0;
  width: 400px;
  height: 100%;
  background-color: ${theme.colors.white[900]};
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: ${(props) => (props.isOpen || props.isMidClosingAnimation ? "flex" : "none")};
  flex-direction: column;
  overflow: hidden;
  animation: ${(props) =>
    props.isOpen
      ? css`
          ${slideUp} 0.3s ease-out forwards
        `
      : props.isMidClosingAnimation
      ? css`
          ${slideDown} 0.2s ease-in forwards
        `
      : "none"};

  @media (max-width: ${(props) => props.theme.breakpoints[500]}) {
    width: 100%;
    border-radius: 0;
  }
`;

const ChatHeader = styled.div`
  color: ${theme.colors.brand[500]};
  padding: 8px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .chat-title {
    font-size: 12px;
    display: flex;
    align-items: center;
  }
  svg {
    width: 32px;
  }
  button {
    all: unset;
    cursor: pointer;
  }
`;

const ChatMessages = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
`;

const UserMessage = styled.div`
  margin-bottom: 14px;
  padding: 16px 24px;
  border-radius: 18px;
  font-size: 14px;
  line-height: 1.4;
  text-align: left;
  position: relative;
  width: 100%;
  min-width: 0;
  background-color: #f5f5f8;
  color: black;
`;

const SystemMessage = styled.div`
  margin-bottom: 14px;
  padding: 16px 24px;
  border-radius: 18px;
  font-size: 14px;
  line-height: 1.4;
  text-align: left;
  position: relative;
  width: 100%;
  min-width: 0;

  background-color: #eceffd;
  color: black;

  .title {
    color: ${theme.colors.brand[500]};
    font-size: 12px;
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    span {
      padding-left: 5px;
    }
  }
`;

const Message = forwardRef<HTMLDivElement, { isUser: boolean; children: ReactNode }>((props, ref) => {
  if (props.isUser) {
    return <UserMessage ref={ref}>{props.children}</UserMessage>;
  }
  return (
    <SystemMessage ref={ref}>
      <div className="title">
        <Sparkles />
        <span>Assistant</span>
      </div>
      {props.children}
    </SystemMessage>
  );
});

const SendMessageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px;
`;

const SendMessageBox = styled.div`
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.15);
  width: 100%;
  display: flex;
  border-radius: 12px;
`;

const SendMessageInputWrapper = styled.div`
  flex-grow: 1;
  background-color: #eef1fe;
  border: 1px solid #3c5dff;
  border-radius: 12px 0 0 12px;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SendMessageIconWrapper = styled.span`
  padding-left: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SendMessageInputField = styled.input`
  background-color: #eef1fe;
  border: 0;
  font-size: 16px;
  &:focus {
    outline: none;
  }
  ::placeholder {
    color: #3c5dff;
    opacity: 50%;
  }
  flex-grow: 1;
  padding: 8px 8px;
  overflow: hidden;
`;

const SendButton = styled.button`
  height: 50px;
  background-color: ${theme.colors.brand[500]};
  color: ${theme.colors.white[900]};
  border: none;
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 0 12px 12px 0;
`;

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
`;

const LoadingDot = styled.span`
  width: 8px;
  height: 8px;
  background-color: ${theme.colors.brand[500]};
  border-radius: 50%;
  display: inline-block;
  margin: 0 4px;
  animation: ${css`
    ${pulse} 1.4s infinite ease-in-out both
  `};

  &:nth-child(1) {
    animation-delay: -0.32s;
  }
  &:nth-child(2) {
    animation-delay: -0.16s;
  }
`;

const StyledSource = styled.button`
  background: none;
  border: none;
  margin-top: 6px;
  padding: 0;
  cursor: pointer;
  color: ${theme.colors.brand[500]};
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 22px;
  min-height: 22px;
  transition: color 0.2s ease;
  &:hover {
    color: ${theme.colors.brand[600]};
  }
  span {
    padding-left: 6px;
  }
`;

const MessageContent = styled.div`
  word-break: break-word;
  overflow-wrap: break-word;
`;

const MessageFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SourceButton: React.FC<{
  article: { title: string; url: string };
  trackStepAction: (actionId: string, extras?: Record<string, any>) => void;
}> = ({ article, trackStepAction }) => {
  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    trackStepAction("source.clicked", {
      articleTitle: article.title,
      articleUrl: article.url,
    });

    if (article.url) {
      const newWindow = window.open();
      if (newWindow) {
        newWindow.opener = null;
        newWindow.location.href = article.url;
      }
    }
  };

  return (
    <StyledSource onClick={handleClick} aria-label={`Open article: ${article.title}`}>
      <PageIcon />
      <span>SOURCE</span>
    </StyledSource>
  );
};

export const DebtAdvisorChat = (props: {
  userContext: IUserContext;
  trackStepAction: (actionId: string, extras?: Record<string, any>) => void;
  trackImpression: (subComponentName: string) => void;
}) => {
  const chat = useChat();
  const [isMidClosingAnimation, setIsMidClosingAnimation] = useState(false);
  const [userInput, setUserInput] = useState("");
  const [impressionTracked, setImpressionTracked] = useState(false);
  const lastMessageRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!chat.isNearBottom()) return;
    if (!lastMessageRef.current && !chat.isLoading) return;

    requestAnimationFrame(() => {
      lastMessageRef.current?.scrollIntoView({ behavior: "smooth" });
    });
  }, [chat.history, chat.isLoading]);

  useEffect(() => {
    if (chat.isOpen) {
      inputRef.current?.focus();
      if (!impressionTracked) {
        props.trackImpression("chat");
        setImpressionTracked(true);
      }
    }
  }, [chat.isOpen]);

  const handleOpen = () => {
    chat.setIsOpen(true);
    setIsMidClosingAnimation(false);
  };

  const handleClose = () => {
    setIsMidClosingAnimation(true);
    chat.setIsOpen(false);
    setTimeout(() => {
      setIsMidClosingAnimation(false);
    }, 300);
  };

  const handleSendMessage = async () => {
    if (!userInput.trim() || chat.isLoading) return;

    setUserInput("");
    await chat.askQuestion(userInput, props.userContext, false);
    props.trackStepAction("messageSent", { ...props.userContext, fromPrompt: false });
  };

  const handleFeedback = (messageId: number, isHelpful: boolean | null) => {
    chat.updateMessageFeedback(messageId, isHelpful);

    track(EVENTS.COMPONENT_ACTION, {
      componentName: "debtAdvisor.ChatFeedback",
      componentVersion: "1.0",
      actionId: "opened",
      actionMethod: isHelpful === null ? "remove" : "add",
      extras: {
        messageId,
        isHelpful,
        optionOfInterest: props.userContext.optionOfInterest,
      },
    });
  };

  const renderMessage = (chat: IChatMessage) => {
    if (typeof chat.text === "string") {
      if (typeof chat.text === "string" && chat.text.includes("Question: ") && chat.text.includes("Answer: ")) {
        return (
          <>
            <QuestionAnswerFormat text={chat.text} />
            <MessageFooter>
              {chat.sender === "ai" && chat.id && chat.articleLink && (
                <FeedbackButtons messageId={chat.id} wasHelpful={chat.wasHelpful} onFeedback={handleFeedback} />
              )}
              {chat.articleLink && JSON.parse(chat.articleLink).length > 0 && (
                <SourceButton article={JSON.parse(chat.articleLink)[0]} trackStepAction={props.trackStepAction} />
              )}
            </MessageFooter>
          </>
        );
      }

      const messageBody =
        typeof chat.text !== "string"
          ? chat.text
          : chat.text.split("\n").map((line, index) => <div key={index}>{line}</div>);

      const articles = chat.articleLink ? JSON.parse(chat.articleLink) : [];
      if (!chat.articleLink || articles.length === 0) {
        return <MessageContent>{messageBody}</MessageContent>;
      }

      return (
        <MessageContent>
          <MessageText text={chat.text} />
          <MessageFooter>
            {chat.sender === "ai" && chat.id && chat.articleLink && (
              <FeedbackButtons messageId={chat.id} wasHelpful={chat.wasHelpful} onFeedback={handleFeedback} />
            )}
            {chat.articleLink && JSON.parse(chat.articleLink).length > 0 && (
              <SourceButton article={JSON.parse(chat.articleLink)[0]} trackStepAction={props.trackStepAction} />
            )}
          </MessageFooter>
        </MessageContent>
      );
    }

    // Only possible with the intro message
    return <MessageContent>{chat.text}</MessageContent>;
  };

  return (
    <div style={{ position: "relative" }}>
      <ChatIcon onClick={handleOpen} />
      <ChatWindow isOpen={chat.isOpen} isMidClosingAnimation={isMidClosingAnimation}>
        <ChatHeader>
          <div className="chat-title">
            <Sparkles />
            <span>Assistant</span>
          </div>
          <button onClick={handleClose}>
            <CloseButton />
          </button>
        </ChatHeader>
        <ChatMessages ref={chat.chatContainerRef}>
          {chat.history.map((message, index) => (
            <Message
              key={message.id}
              isUser={message.sender === "user"}
              ref={index === chat.history.length - 1 ? lastMessageRef : null}
            >
              {renderMessage(message)}
            </Message>
          ))}
          {chat.isLoading && (
            <LoadingWrapper>
              <LoadingDot />
              <LoadingDot />
              <LoadingDot />
            </LoadingWrapper>
          )}
        </ChatMessages>
        <SendMessageWrapper>
          <SendMessageBox>
            <SendMessageInputWrapper>
              <SendMessageIconWrapper>
                <MessageIcon />
              </SendMessageIconWrapper>
              <SendMessageInputField
                ref={inputRef}
                value={userInput}
                onChange={(e) => {
                  if (e.target.value.length > 0) {
                    chat.updateLastTypingTime();
                  }

                  setUserInput(e.target.value);
                }}
                onKeyPress={(e) => e.key === "Enter" && handleSendMessage()}
                placeholder="Ask a question"
              />
            </SendMessageInputWrapper>
            <SendButton onClick={handleSendMessage} disabled={chat.isLoading}>
              <SendIcon />
            </SendButton>
          </SendMessageBox>
        </SendMessageWrapper>
      </ChatWindow>
    </div>
  );
};
